/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Collection List Component
-------------------------------- */
/*
Mobile responsive - ideal to display grid when on mobile devices
*/



// % protected region % [Change collection view styles here] off begin
.collection-component {

	/***** Top section of collection *****/
	@import 'collection-menu-actions.scss';


	/***** List style/Table style *****/
	@import 'collection-list-view.scss';

	/************ GRID VIEW ***************/
	@import 'collection-grid-view.scss';

	.collection__grid,
	.collection__list {
		.input-group {
			display: flex;
			min-width: unset;
		}
	}

	//pagination styles for collections if necessary
	.collection__pagination {
		display: flex;
		justify-content: flex-end;
		list-style-type: none;
		display: flex;
		align-content: center;
		align-items: center;

		li {
			.btn {
				padding: $admin-space-xs $admin-space-sm;

				&:before,
				&:after {
					margin: 0 $admin-space-xs;
				}

				&:hover,
				&:active {
					text-decoration: none;
					color: $admin-color-primary;
				}
			}

			.pagination__page-number {
				margin: 0 $admin-space-md;
				width: max-content;
				display: flex;
				@media screen and (max-width: 620px) {
					margin: 0;
				}
			}
		}
	}

	@import 'collection-mobile.scss';
}

// % protected region % [Change collection view styles here] end
// % protected region % [Add additional collection view styles here] off begin
// % protected region % [Add additional collection view styles here] end