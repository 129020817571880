/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */



// % protected region % [Change admin dashboard styles here] off begin
.dashboard {
	margin: 2rem 4rem;

	.option-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;

	}
	
	.dashboard-bot-version {
		width: fit-content;
		padding: 0.7rem;
		border-radius: 1rem;
		background-color: $admin-grey-0;
		display: flex;
		gap: $admin-space-xs;
		margin: $admin-space-xs;
		color: $admin-grey-6;
		p {
			margin: 0;
			font-size: larger;
		}
	}

	.dashboard-heading {
		margin-bottom: 4rem;

		h2 {
			margin-bottom: 1rem;
		}

		p {
			font-size: 1.3rem;
		}
	}

	.dashboard-cards {
		max-width: 50rem;

		.cards-wrapper {
			
			.icon {
				height: 3.3rem;
			}
			
			.card {
				width: 100%;
				background-color: $admin-grey-0;
				min-width: 0;
				
			@media screen and (max-width: 620px) {
				margin-left: 0;
				margin-right: 0;
			}
				
				.icon {
					font-size: 2.5rem;
				}

				h4 {
					font-size: 1.3rem;
					margin: 1rem 0;
				}

				p {
					color: $admin-grey-6;
				}

				&.card--learn-more {
					position: relative;
					overflow: hidden;
					@include flare;
					h4, p {
						color: $admin-white;
					}
					.card--content {
						margin-top: 2rem;
					}
					.planet--primary {
						width: 40rem;
						padding-bottom: 40rem;
						right: -21rem;
						bottom: -37rem;
						background-color: $color-support-blue;
						border-radius: 40rem;
						position: absolute;
					}
					.planet--secondary {
						width: 40rem;
						padding-bottom: 40rem;
						left: -16rem;
						top: -38rem;
						background-color: $color-support-green;
						border-radius: 160%;
						position: absolute;
					}
					.planet--sub {
						width: 3rem;
						padding-bottom: 3rem;
						left: 1rem;
						top: 1rem;
						background-color: $color-support-yellow;
						border-radius: 160%;
						position: absolute;
					}
				}
			}
		}
	}
}
// % protected region % [Change admin dashboard styles here] end

// % protected region % [Customise the dashboard page here] off begin
// % protected region % [Customise the dashboard page here] end