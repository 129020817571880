/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Cards
-------------------------------- */
/*
Cards are often used to display a group of component inside a 'box'
*/

// % protected region % [Change cardsstyles here] off begin
.cards-wrapper {
	display: flex;
	margin: 0 auto;
	flex-wrap: wrap;

	//wrapper for card with icon with an icon
	&--icon {
		.icon-only {
			font-size: $text-xxxl;
			padding: 0;
			margin-bottom: $space-xs;
		}

		p {
			font-size: $text-md;
			margin-bottom: 0;
		}
	}

	//wrapper for card with smaller icon
	&--icon-small {
		.icon-only {
			font-size: $text-xl;
			padding: 0;
			margin-bottom: $space-xs;
		}
	}

	//wrapper for horizontal cards
	&.horizontal {
		flex-direction: row;
		margin: 0 auto;

		.card {
			margin: $space-xs;
		}
	}

	//wrapper for vertical cards
	&.vertical {
		flex-direction: column;

		.card {
			margin: $space-xs auto;
		}
	}

	//card with an icon
	&--icon {
		.icon-only {
			font-size: $text-xxxl;
			padding: 0;
			margin-bottom: $space-xs;
		}

		p {
			font-size: $text-md;
			margin-bottom: 0;
		}
	}

	//the card itself
	.card {
		padding: $space-md;
		flex-basis: convert-rem(250px);
		min-height: convert-rem(120px);
		min-width: convert-rem(250px);
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex-grow: 1;
		text-decoration: none;

		&--rounded {
			border-radius: .5rem;

			//apply default bg color
			background-color: $grey-1;
			color: $black;
		}

		.card--content {
			display: table;
			margin: 0 auto;
		}

		&:hover,
		&:focus,
		&:active {
			box-shadow: none;
			border-bottom: none;
			transition: none;
		}
	}
}
// % protected region % [Change cardsstyles here] end

// % protected region % [Add additional cards styles here] off begin
// % protected region % [Add additional cards styles here] end