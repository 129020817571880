/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Workflow Landing page 
-------------------------------- */
/* 
Page with the boxes before you edit a workflow
*/

// % protected region % [Change workflows landing view styles here] off begin
&.workflow-behaviour__landing {
	padding: $admin-space-md $admin-space-lg;
	overflow-y: auto;

	.workflow-block-items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		a {
			@include behaviour-blocks-a;
		}

		.workflow-item__new {
			@include behaviour-blocks-new;
		}

		.workflow-item {
			@include behaviour-blocks;

			.workflow-item__heading {
				h3 {
					font-weight: $admin-text-medium;
				}

				.workflow-entities {
					margin: 0;
					font-size: $admin-text-sm;
				}
			}

			.workflow-item__footer {
				display: flex;
				justify-content: space-between;
				align-content: baseline;
				max-height: convert-rem(40px);
				margin-top: auto;

				.workflow-item__version {
					@include block-versions;
				}

				.icon-more-horizontal {
					margin-left: auto;
					padding: 0;

					&:before {
						margin: 0;
					}
				}
			}
		}
	}
}
// % protected region % [Change workflows landing view styles here] end
// % protected region % [Add additional workflows landing view styles here] off begin
// % protected region % [Add additional workflows landing view styles here] end