/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Icons
These are the lightning icon variables list
https://medium.com/codyhouse/create-your-design-system-part-5-icons-594f39cfb1b
-------------------------------- */
/* TO use an icon icon($admin-position: before, $admin-icon: false, $admin-styles: true) */
/*https://jaydenseric.com/blog/fun-with-sass-and-font-icons*/
/* current provided icons : https://cdn.codebots.com/lampbot/lightning-icons/latest/lightning-icons.css */
/* You can add additional fonts or change the fonts provided.
for example you would want to change the "\e900" part once you have new fonts created
or you can add a new one ie: apple: "\e289"*/
//Import Icon Fonts
@import url("https://cdn.codebots.com/lampbot/lightning-icons/latest/lightning-icons.css");

$admin-icons: (
	"3-way-split-bottom": "\e900",
	"3-way-split-left": "\e901",
	"3-way-split-right": "\e902",
	"3-way-split-top": "\e903",
	"academy": "\e904",
	"access-database": "\e905",
	"accessiblity": "\e906",
	"accessories": "\e907",
	"act": "\e908",
	"actions": "\e909",
	"activity": "\e90a",
	"africa": "\e90b",
	"agile": "\e90c",
	"ai": "\e90d",
	"air-con": "\e90e",
	"airplay": "\e90f",
	"alert": "\e910",
	"align-centre": "\e911",
	"align-justify": "\e912",
	"align-left": "\e913",
	"align-right": "\e914",
	"alpha": "\e915",
	"alpha-2": "\e916",
	"anchor": "\e917",
	"android": "\e918",
	"anzac": "\e919",
	"aperture": "\e91a",
	"api": "\e91b",
	"app": "\e91c",
	"apple": "\e91d",
	"aquila": "\e91e",
	"arc-170": "\e91f",
	"architect": "\e920",
	"archivable": "\e921",
	"armour": "\e922",
	"army": "\e923",
	"arrow-down": "\e924",
	"arrow-down-circle": "\e925",
	"arrow-left": "\e926",
	"arrow-left-circle": "\e927",
	"arrow-left-down": "\e928",
	"arrow-left-up": "\e929",
	"arrow-right": "\e92a",
	"arrow-right-cricle": "\e92b",
	"arrow-right-down": "\e92c",
	"arrow-right-up": "\e92d",
	"arrow-up": "\e92e",
	"arrow-up-circle": "\e92f",
	"artboard": "\e930",
	"article": "\e931",
	"asia-pacific": "\e932",
	"atlassian": "\e933",
	"at-sign": "\e934",
	"attributes": "\e935",
	"aurora": "\e936",
	"australia": "\e937",
	"auto-layout": "\e938",
	"avocado": "\e939",
	"award": "\e93a",
	"axe": "\e93b",
	"background-story": "\e93c",
	"backlog": "\e93d",
	"backstab": "\e93e",
	"backwards-circle": "\e93f",
	"bacon": "\e940",
	"badge": "\e941",
	"balance": "\e942",
	"balloon": "\e943",
	"banner": "\e944",
	"barbarian": "\e945",
	"bard": "\e946",
	"bath": "\e947",
	"battery": "\e948",
	"battery-charge-1": "\e949",
	"battery-charge-2": "\e94a",
	"battery-charge-3": "\e94b",
	"battery-charging": "\e94c",
	"battleboard": "\e94d",
	"bdg": "\e94e",
	"beach": "\e94f",
	"bean": "\e950",
	"bed": "\e951",
	"beer": "\e952",
	"behaviours": "\e953",
	"bell": "\e954",
	"bell-off": "\e955",
	"bell-snooze": "\e956",
	"belt": "\e957",
	"beta": "\e958",
	"beta-2": "\e959",
	"bicycle": "\e95a",
	"bin-2": "\e95b",
	"bin-delete": "\e95c",
	"bin-empty": "\e95d",
	"bin-full": "\e95e",
	"bird": "\e95f",
	"blame": "\e960",
	"bluetooth": "\e961",
	"board": "\e962",
	"bold": "\e963",
	"bomb": "\e964",
	"book": "\e965",
	"bookmark": "\e966",
	"book-open": "\e967",
	"boolean": "\e968",
	"borg": "\e969",
	"bot": "\e96a",
	"bot-chat": "\e96b",
	"bot-delete": "\e96c",
	"bot-new": "\e96d",
	"bot-remove": "\e96e",
	"bow": "\e96f",
	"box": "\e970",
	"box-minus": "\e971",
	"box-plus": "\e972",
	"bread": "\e973",
	"breakfast": "\e974",
	"brief": "\e975",
	"briefcase": "\e976",
	"brightness-1": "\e977",
	"brightness-2": "\e978",
	"bring-forward": "\e979",
	"bring-to-front": "\e97a",
	"brisbane": "\e97b",
	"brush": "\e97c",
	"btn": "\e97d",
	"bucket": "\e97e",
	"buff-the-worm": "\e97f",
	"bug": "\e980",
	"bug-squash": "\e981",
	"buildings": "\e982",
	"bus": "\e983",
	"bush-food": "\e984",
	"cable": "\e985",
	"cactus": "\e986",
	"calculate": "\e987",
	"calender": "\e988",
	"camera": "\e989",
	"camera-off": "\e98a",
	"cancel": "\e98b",
	"canvas": "\e98c",
	"canvas-switch": "\e98d",
	"captain": "\e98e",
	"car": "\e98f",
	"cards": "\e990",
	"care-bed": "\e991",
	"care-equipment": "\e992",
	"car-park": "\e993",
	"cart": "\e994",
	"cast": "\e995",
	"cat": "\e996",
	"categories": "\e997",
	"change": "\e998",
	"change-product": "\e999",
	"channel": "\e99a",
	"chart-bar-1": "\e99b",
	"chart-bar-2": "\e99c",
	"chart-bar-3": "\e99d",
	"chart-bar-4": "\e99e",
	"chart-line": "\e99f",
	"chart-pie": "\e9a0",
	"chats": "\e9a1",
	"check": "\e9a2",
	"check-box": "\e9a3",
	"check-circle": "\e9a4",
	"check-circle-2": "\e9a5",
	"check-out": "\e9a6",
	"cheese": "\e9a7",
	"chess": "\e9a8",
	"chevron-down": "\e9a9",
	"chevron-left": "\e9aa",
	"chevron-right": "\e9ab",
	"chevrons-down": "\e9ac",
	"chevrons-left": "\e9ad",
	"chevrons-right": "\e9ae",
	"chevrons-up": "\e9af",
	"chevron-up": "\e9b0",
	"chicken": "\e9b1",
	"child": "\e9b2",
	"children": "\e9b3",
	"christmas": "\e9b4",
	"chrome": "\e9b5",
	"circle": "\e9b6",
	"circle-add": "\e9b7",
	"circle-bot": "\e9b8",
	"circle-delete": "\e9b9",
	"circle-fill": "\e9ba",
	"circle-minus": "\e9bb",
	"cis": "\e9bc",
	"clean": "\e9bd",
	"cleric": "\e9be",
	"clipboard": "\e9bf",
	"clock": "\e9c0",
	"clone": "\e9c1",
	"cloth": "\e9c2",
	"cloud": "\e9c3",
	"cloud-code": "\e9c4",
	"cloud-download": "\e9c5",
	"cloud-drizzle": "\e9c6",
	"cloud-off": "\e9c7",
	"cloud-power": "\e9c8",
	"cloud-rain": "\e9c9",
	"cloud-snow": "\e9ca",
	"cloud-upload": "\e9cb",
	"cocktail": "\e9cc",
	"code": "\e9cd",
	"codebots": "\e9ce",
	"codebots-og": "\e9cf",
	"codepen": "\e9d0",
	"coffee": "\e9d1",
	"cold": "\e9d2",
	"collapse-right": "\e9d3",
	"collaspe": "\e9d4",
	"collaspe-left": "\e9d5",
	"column": "\e9d6",
	"comet": "\e9d7",
	"command": "\e9d8",
	"community": "\e9d9",
	"compass": "\e9da",
	"component": "\e9db",
	"conference": "\e9dc",
	"conflict": "\e9dd",
	"confluence": "\e9de",
	"connection": "\e9df",
	"constellation": "\e9e0",
	"contrast": "\e9e1",
	"controller": "\e9e2",
	"copy": "\e9e3",
	"corner-down-left": "\e9e4",
	"corner-down-right": "\e9e5",
	"corner-left-down": "\e9e6",
	"corner-left-up": "\e9e7",
	"corner-right-down": "\e9e8",
	"corner-right-up": "\e9e9",
	"corner-up-left": "\e9ea",
	"corner-up-right": "\e9eb",
	"cow": "\e9ec",
	"credit-card": "\e9ed",
	"crop": "\e9ee",
	"crosshair": "\e9ef",
	"crown": "\e9f0",
	"crud": "\e9f1",
	"csharpbot": "\e9f2",
	"css": "\e9f3",
	"cube": "\e9f4",
	"cube-add": "\e9f5",
	"cube-delete": "\e9f6",
	"cube-minus": "\e9f7",
	"cut": "\e9f8",
	"cygnas": "\e9f9",
	"d4": "\e9fa",
	"d6": "\e9fb",
	"d8": "\e9fc",
	"d10": "\e9fd",
	"d12": "\e9fe",
	"d20": "\e9ff",
	"d20-dot": "\ea00",
	"d100": "\ea01",
	"dagger": "\ea02",
	"dance": "\ea03",
	"dark-matter": "\ea04",
	"dashboard": "\ea05",
	"database": "\ea06",
	"database-2": "\ea07",
	"date-time": "\ea08",
	"dead": "\ea09",
	"decimal": "\ea0a",
	"delete": "\ea0b",
	"delete-column": "\ea0c",
	"delete-row": "\ea0d",
	"deliverable": "\ea0e",
	"demote": "\ea0f",
	"deploy": "\ea10",
	"desert": "\ea11",
	"desk": "\ea12",
	"desk-stand": "\ea13",
	"desktop": "\ea14",
	"dessert": "\ea15",
	"details": "\ea16",
	"development": "\ea17",
	"devices": "\ea18",
	"diamond": "\ea19",
	"dice": "\ea1a",
	"dinner": "\ea1b",
	"disc": "\ea1c",
	"disconnect": "\ea1d",
	"display-all": "\ea1e",
	"divide": "\ea1f",
	"dog": "\ea20",
	"download": "\ea21",
	"dragon": "\ea22",
	"draw": "\ea23",
	"draws": "\ea24",
	"dress": "\ea25",
	"drip": "\ea26",
	"dropbox": "\ea27",
	"druid": "\ea28",
	"dual-screen": "\ea29",
	"duck": "\ea2a",
	"duplicate": "\ea2b",
	"easter": "\ea2c",
	"edge": "\ea2d",
	"edit": "\ea2e",
	"edit-box": "\ea2f",
	"edit-line": "\ea30",
	"egg": "\ea31",
	"element": "\ea32",
	"emoji": "\ea33",
	"empire": "\ea34",
	"enhancement": "\ea35",
	"entity": "\ea36",
	"enum": "\ea37",
	"environments": "\ea38",
	"epic": "\ea39",
	"error": "\ea3a",
	"ethernet": "\ea3b",
	"europe": "\ea3c",
	"exclamation": "\ea3d",
	"expand": "\ea3e",
	"expand-2": "\ea3f",
	"export": "\ea40",
	"eye-dropper": "\ea41",
	"eye-track": "\ea42",
	"facebook": "\ea43",
	"fan": "\ea44",
	"fastfoward": "\ea45",
	"feather": "\ea46",
	"feature": "\ea47",
	"feed": "\ea48",
	"feedback": "\ea49",
	"female": "\ea4a",
	"female-female": "\ea4b",
	"female-male": "\ea4c",
	"fetch": "\ea4d",
	"field-trial": "\ea4e",
	"fighter": "\ea4f",
	"file": "\ea50",
	"file-add": "\ea51",
	"file-code": "\ea52",
	"file-copy": "\ea53",
	"file-delete": "\ea54",
	"file-docx": "\ea55",
	"file-img": "\ea56",
	"file-remove": "\ea57",
	"files": "\ea58",
	"file-stack": "\ea59",
	"file-text": "\ea5a",
	"film": "\ea5b",
	"filter": "\ea5c",
	"finger-print": "\ea5d",
	"finish": "\ea5e",
	"fire": "\ea5f",
	"fireworks": "\ea60",
	"fish": "\ea61",
	"flag": "\ea62",
	"flex": "\ea63",
	"flow": "\ea64",
	"flow-break": "\ea65",
	"flow-connect": "\ea66",
	"flow-connect-type": "\ea67",
	"flow-data": "\ea68",
	"flow-directional": "\ea69",
	"flower": "\ea6a",
	"flow-user": "\ea6b",
	"flow-web": "\ea6c",
	"fob": "\ea6d",
	"folder": "\ea6e",
	"folder-add": "\ea6f",
	"folder-copy": "\ea70",
	"folder-delete": "\ea71",
	"folder-open": "\ea72",
	"folder-remove": "\ea73",
	"food": "\ea74",
	"football": "\ea75",
	"footer": "\ea76",
	"forms": "\ea77",
	"fosnax": "\ea78",
	"fountain-pen": "\ea79",
	"foward-circle": "\ea7a",
	"fox": "\ea7b",
	"full-moon": "\ea7c",
	"gears": "\ea7d",
	"gift": "\ea7e",
	"git-branch": "\ea7f",
	"git-commit": "\ea80",
	"github": "\ea81",
	"gitlab": "\ea82",
	"git-merge": "\ea83",
	"git-pull-request": "\ea84",
	"glass": "\ea85",
	"globe": "\ea86",
	"golf": "\ea87",
	"google-drive": "\ea88",
	"grab-that-moon": "\ea89",
	"gravestone": "\ea8a",
	"grid": "\ea8b",
	"gun": "\ea8c",
	"gutter": "\ea8d",
	"gym": "\ea8e",
	"hammer": "\ea8f",
	"hand-grab": "\ea90",
	"hand-ok": "\ea91",
	"hand-open": "\ea92",
	"hand-point": "\ea93",
	"hand-wave": "\ea94",
	"hard-drive": "\ea95",
	"harmony": "\ea96",
	"hashtag": "\ea97",
	"hat": "\ea98",
	"header": "\ea99",
	"heading": "\ea9a",
	"headphones": "\ea9b",
	"health": "\ea9c",
	"heart": "\ea9d",
	"heart-broken": "\ea9e",
	"heart-fill": "\ea9f",
	"heart-large": "\eaa0",
	"heart-large-broken": "\eaa1",
	"helicopter": "\eaa2",
	"helmet": "\eaa3",
	"help": "\eaa4",
	"hexagon": "\eaa5",
	"hexagon-add": "\eaa6",
	"hexagon-delete": "\eaa7",
	"hexagon-fill": "\eaa8",
	"hexagon-minus": "\eaa9",
	"hidden": "\eaaa",
	"hike": "\eaab",
	"historical": "\eaac",
	"hoist": "\eaad",
	"home": "\eaae",
	"honey": "\eaaf",
	"horizontal": "\eab0",
	"horizontal-split": "\eab1",
	"hospital": "\eab2",
	"hot": "\eab3",
	"hourglass": "\eab4",
	"hourglass-empty": "\eab5",
	"hr": "\eab6",
	"html": "\eab7",
	"ice-cream": "\eab8",
	"idea": "\eab9",
	"ie": "\eaba",
	"img": "\eabb",
	"import": "\eabc",
	"inbox": "\eabd",
	"indent-in": "\eabe",
	"indent-out": "\eabf",
	"information": "\eac0",
	"input": "\eac1",
	"insert-column": "\eac2",
	"insert-row": "\eac3",
	"instagram": "\eac4",
	"integer": "\eac5",
	"investigate": "\eac6",
	"iot": "\eac7",
	"issue": "\eac8",
	"italics": "\eac9",
	"iteration": "\eaca",
	"jake": "\eacb",
	"jam": "\eacc",
	"jay": "\eacd",
	"jedi": "\eace",
	"jenkins": "\eacf",
	"jewel": "\ead0",
	"jira": "\ead1",
	"jordie": "\ead2",
	"josh": "\ead3",
	"js": "\ead4",
	"juice": "\ead5",
	"jumper": "\ead6",
	"key": "\ead7",
	"keyboard": "\ead8",
	"knight": "\ead9",
	"lampbot": "\eada",
	"landscape": "\eadb",
	"laptop": "\eadc",
	"launch": "\eadd",
	"layers": "\eade",
	"layout": "\eadf",
	"lead": "\eae0",
	"leaf": "\eae1",
	"left-right": "\eae2",
	"lens": "\eae3",
	"leo": "\eae4",
	"lesson": "\eae5",
	"letter": "\eae6",
	"library": "\eae7",
	"library-item": "\eae8",
	"life-buoy": "\eae9",
	"light-bulb": "\eaea",
	"lighthouse": "\eaeb",
	"lightning": "\eaec",
	"lightning-off": "\eaed",
	"lightsaber": "\eaee",
	"line-height": "\eaef",
	"link-1": "\eaf0",
	"link-2": "\eaf1",
	"link-broken": "\eaf2",
	"linkedin": "\eaf3",
	"link-external": "\eaf4",
	"link-internal": "\eaf5",
	"list": "\eaf6",
	"literals": "\eaf7",
	"loader": "\eaf8",
	"location-map": "\eaf9",
	"location-pin": "\eafa",
	"lock": "\eafb",
	"lock-open": "\eafc",
	"login": "\eafd",
	"logout": "\eafe",
	"lolly": "\eaff",
	"look": "\eb00",
	"look-off": "\eb01",
	"loudspeaker": "\eb02",
	"lounge": "\eb03",
	"lunch": "\eb04",
	"lyra": "\eb05",
	"magic": "\eb06",
	"mail": "\eb07",
	"mail-open": "\eb08",
	"maintance": "\eb09",
	"male": "\eb0a",
	"male-female": "\eb0b",
	"male-male": "\eb0c",
	"man": "\eb0d",
	"management": "\eb0e",
	"man-child": "\eb0f",
	"many-to-many": "\eb10",
	"map": "\eb11",
	"margin": "\eb12",
	"marketing": "\eb13",
	"maximise": "\eb14",
	"maxminise-2": "\eb15",
	"meat": "\eb16",
	"meeting": "\eb17",
	"menu": "\eb18",
	"message": "\eb19",
	"message-circle": "\eb1a",
	"mic": "\eb1b",
	"mic-off": "\eb1c",
	"micronode": "\eb1d",
	"microphone": "\eb1e",
	"microwave": "\eb1f",
	"mikaela": "\eb20",
	"minimise": "\eb21",
	"minimise-2": "\eb22",
	"minus": "\eb23",
	"minus-circle": "\eb24",
	"misson": "\eb25",
	"mobile": "\eb26",
	"model": "\eb27",
	"money": "\eb28",
	"monk": "\eb29",
	"moon": "\eb2a",
	"more-horizontal": "\eb2b",
	"more-vertical": "\eb2c",
	"motorbike": "\eb2d",
	"mountain": "\eb2e",
	"mouse": "\eb2f",
	"move": "\eb30",
	"music": "\eb31",
	"naveen": "\eb32",
	"navigation": "\eb33",
	"navigation-2": "\eb34",
	"nebula": "\eb35",
	"network": "\eb36",
	"new-zealand": "\eb37",
	"nicola": "\eb38",
	"north-america": "\eb39",
	"note": "\eb3a",
	"nova": "\eb3b",
	"nsw": "\eb3c",
	"nt": "\eb3d",
	"nuts": "\eb3e",
	"nx-01": "\eb3f",
	"oceania": "\eb40",
	"olive-oil": "\eb41",
	"ol-list": "\eb42",
	"onboarding": "\eb43",
	"one-to-many": "\eb44",
	"one-to-one": "\eb45",
	"open-left": "\eb46",
	"open-right": "\eb47",
	"opera": "\eb48",
	"order": "\eb49",
	"outback": "\eb4a",
	"package": "\eb4b",
	"pacman": "\eb4c",
	"paint-brush": "\eb4d",
	"paladin": "\eb4e",
	"palette": "\eb4f",
	"pants": "\eb50",
	"paperclip": "\eb51",
	"paper-plane": "\eb52",
	"park": "\eb53",
	"party": "\eb54",
	"paste": "\eb55",
	"path": "\eb56",
	"pattern-library": "\eb57",
	"pause": "\eb58",
	"pause-circle": "\eb59",
	"peanut": "\eb5a",
	"pen": "\eb5b",
	"person": "\eb5c",
	"person-add": "\eb5d",
	"person-check": "\eb5e",
	"person-delete": "\eb5f",
	"person-group": "\eb60",
	"person-remove": "\eb61",
	"phoenix": "\eb62",
	"phone": "\eb63",
	"phonebooth": "\eb64",
	"phone-call": "\eb65",
	"phone-forwarded": "\eb66",
	"phone-incoming": "\eb67",
	"phone-missed": "\eb68",
	"phone-off": "\eb69",
	"phone-outgoing": "\eb6a",
	"php": "\eb6b",
	"picto": "\eb6c",
	"pig": "\eb6d",
	"pin": "\eb6e",
	"pin-2": "\eb6f",
	"ping-pong": "\eb70",
	"pirate": "\eb71",
	"pizza": "\eb72",
	"plan": "\eb73",
	"plane": "\eb74",
	"plant": "\eb75",
	"plate": "\eb76",
	"platform": "\eb77",
	"play": "\eb78",
	"play-circle": "\eb79",
	"plugin": "\eb7a",
	"plus": "\eb7b",
	"plus-circle": "\eb7c",
	"pocket": "\eb7d",
	"pointer": "\eb7e",
	"poll": "\eb7f",
	"pool": "\eb80",
	"poster": "\eb81",
	"post-it": "\eb82",
	"power": "\eb83",
	"pray": "\eb84",
	"presentation": "\eb85",
	"printer": "\eb86",
	"problem": "\eb87",
	"process": "\eb88",
	"product": "\eb89",
	"production": "\eb8a",
	"production-2": "\eb8b",
	"application": "\eb8c",
	"application-new": "\eb8d",
	"promote": "\eb8e",
	"properties": "\eb8f",
	"prototype": "\eb90",
	"pullup-banner": "\eb91",
	"puppet": "\eb92",
	"puzzle": "\eb93",
	"pyxis": "\eb94",
	"qld": "\eb95",
	"qualification": "\eb96",
	"question": "\eb97",
	"quill": "\eb98",
	"quote": "\eb99",
	"quote-end": "\eb9a",
	"radio": "\eb9b",
	"railroad": "\eb9c",
	"ramp": "\eb9d",
	"ranger": "\eb9e",
	"rank": "\eb9f",
	"reader": "\eba0",
	"rearrange": "\eba1",
	"rebels": "\eba2",
	"recycle": "\eba3",
	"redo": "\eba4",
	"refresh-ccw": "\eba5",
	"refresh-cw": "\eba6",
	"remote": "\eba7",
	"reorder": "\eba8",
	"repair": "\eba9",
	"repeat": "\ebaa",
	"requirements": "\ebab",
	"reset": "\ebac",
	"resolve": "\ebad",
	"results": "\ebae",
	"reublic": "\ebaf",
	"rewind": "\ebb0",
	"rhiannon": "\ebb1",
	"ribs": "\ebb2",
	"rides": "\ebb3",
	"river": "\ebb4",
	"roadmap": "\ebb5",
	"rocket": "\ebb6",
	"rogue": "\ebb7",
	"rogue-two": "\ebb8",
	"room": "\ebb9",
	"root": "\ebba",
	"rotate-ccw": "\ebbb",
	"rotate-ccw-2": "\ebbc",
	"rotate-cw": "\ebbd",
	"rotate-cw-2": "\ebbe",
	"row": "\ebbf",
	"rss": "\ebc0",
	"running": "\ebc1",
	"sa": "\ebc2",
	"sad": "\ebc3",
	"sail-ship": "\ebc4",
	"sale": "\ebc5",
	"salt": "\ebc6",
	"satellite": "\ebc7",
	"save": "\ebc8",
	"scan": "\ebc9",
	"scissors": "\ebca",
	"scooter": "\ebcb",
	"scope": "\ebcc",
	"screen-share": "\ebcd",
	"search": "\ebce",
	"secret": "\ebcf",
	"security-camera": "\ebd0",
	"select-column": "\ebd1",
	"select-row": "\ebd2",
	"send-backwards": "\ebd3",
	"send-to-back": "\ebd4",
	"seo": "\ebd5",
	"sequence": "\ebd6",
	"serena": "\ebd7",
	"server": "\ebd8",
	"session": "\ebd9",
	"settings": "\ebda",
	"share": "\ebdb",
	"share-2": "\ebdc",
	"shared-library": "\ebdd",
	"shark": "\ebde",
	"shield": "\ebdf",
	"shield-off": "\ebe0",
	"ship": "\ebe1",
	"shirt": "\ebe2",
	"shoe": "\ebe3",
	"shop": "\ebe4",
	"shorts": "\ebe5",
	"shower": "\ebe6",
	"shrink": "\ebe7",
	"shuffle": "\ebe8",
	"sidebar": "\ebe9",
	"site-child": "\ebea",
	"site-parent": "\ebeb",
	"sith": "\ebec",
	"skate": "\ebed",
	"ski": "\ebee",
	"skip-backward": "\ebef",
	"skip-backwards-circle": "\ebf0",
	"skip-forward": "\ebf1",
	"skip-foward-circle": "\ebf2",
	"skirt": "\ebf3",
	"slack": "\ebf4",
	"slash": "\ebf5",
	"slice": "\ebf6",
	"slide": "\ebf7",
	"sliders-1": "\ebf8",
	"sliders-2": "\ebf9",
	"smaller": "\ebfa",
	"snack": "\ebfb",
	"snow": "\ebfc",
	"soccer": "\ebfd",
	"social": "\ebfe",
	"sorcerer": "\ebff",
	"sound-off": "\ec00",
	"sound-on": "\ec01",
	"source": "\ec02",
	"source-code": "\ec03",
	"south-america": "\ec04",
	"southern-cross": "\ec05",
	"space": "\ec06",
	"spacebar": "\ec07",
	"space-invader": "\ec08",
	"spanner": "\ec09",
	"speaker": "\ec0a",
	"spear": "\ec0b",
	"sports": "\ec0c",
	"spreadsheet": "\ec0d",
	"springbot": "\ec0e",
	"sprint": "\ec0f",
	"square-bot": "\ec10",
	"square-fill": "\ec11",
	"square-x": "\ec12",
	"stall": "\ec13",
	"star": "\ec14",
	"star-fill": "\ec15",
	"stop-circle": "\ec16",
	"stories": "\ec17",
	"strike": "\ec18",
	"string": "\ec19",
	"structure": "\ec1a",
	"subscript": "\ec1b",
	"suggestion": "\ec1c",
	"sun": "\ec1d",
	"sunglasses": "\ec1e",
	"sunrise": "\ec1f",
	"sunset": "\ec20",
	"superscript": "\ec21",
	"sushi": "\ec22",
	"sword": "\ec23",
	"table": "\ec24",
	"tablet": "\ec25",
	"tag": "\ec26",
	"tag-add": "\ec27",
	"tag-remove": "\ec28",
	"tank": "\ec29",
	"target": "\ec2a",
	"tas": "\ec2b",
	"task": "\ec2c",
	"tea": "\ec2d",
	"teach": "\ec2e",
	"team": "\ec2f",
	"terminal": "\ec30",
	"tessa": "\ec31",
	"tests": "\ec32",
	"test-tubes": "\ec33",
	"text-larger": "\ec34",
	"text-smaller": "\ec35",
	"text-to-speech": "\ec36",
	"thermometer": "\ec37",
	"thumbs-down": "\ec38",
	"thumbs-up": "\ec39",
	"tie": "\ec3a",
	"tie-fighter": "\ec3b",
	"tile": "\ec3c",
	"timeline": "\ec3d",
	"toggle-left": "\ec3e",
	"toogle-right": "\ec3f",
	"torch": "\ec40",
	"touch": "\ec41",
	"town-hall": "\ec42",
	"toy": "\ec43",
	"train": "\ec44",
	"tree": "\ec45",
	"trello": "\ec46",
	"trend-down": "\ec47",
	"trend-up": "\ec48",
	"triangle": "\ec49",
	"triangle-add": "\ec4a",
	"triangle-bot": "\ec4b",
	"triangle-delete": "\ec4c",
	"triangle-down": "\ec4d",
	"triangle-fill": "\ec4e",
	"triangle-minus": "\ec4f",
	"triangle-up": "\ec50",
	"trophy": "\ec51",
	"truck": "\ec52",
	"tv": "\ec53",
	"twitter": "\ec54",
	"type": "\ec55",
	"uat": "\ec56",
	"umbrella": "\ec57",
	"uncollaspe": "\ec58",
	"underline": "\ec59",
	"underwear": "\ec5a",
	"undo": "\ec5b",
	"up-down": "\ec5c",
	"upload": "\ec5d",
	"usb": "\ec5e",
	"uss-defiant": "\ec5f",
	"uss-enterprise-a": "\ec60",
	"uss-enterprise-d": "\ec61",
	"uss-voyager": "\ec62",
	"vegetables": "\ec63",
	"versions": "\ec64",
	"vertical-split": "\ec65",
	"vic": "\ec66",
	"video": "\ec67",
	"video-controller": "\ec68",
	"video-off": "\ec69",
	"view": "\ec6a",
	"viking": "\ec6b",
	"vimeo": "\ec6c",
	"voicemail": "\ec6d",
	"void": "\ec6e",
	"volcano": "\ec6f",
	"volume": "\ec70",
	"volume-1": "\ec71",
	"volume-2": "\ec72",
	"volume-x": "\ec73",
	"vr": "\ec74",
	"vulture-droid": "\ec75",
	"wa": "\ec76",
	"walk": "\ec77",
	"wallet": "\ec78",
	"warlock": "\ec79",
	"warning": "\ec7a",
	"watch": "\ec7b",
	"water": "\ec7c",
	"waterfall": "\ec7d",
	"wave": "\ec7e",
	"web-cam": "\ec7f",
	"wheat": "\ec80",
	"wheel": "\ec81",
	"wheel-menu": "\ec82",
	"whip": "\ec83",
	"whiteboard": "\ec84",
	"wifi": "\ec85",
	"wifi-off": "\ec86",
	"wind": "\ec87",
	"windows": "\ec88",
	"wine": "\ec89",
	"wizard": "\ec8a",
	"woman": "\ec8b",
	"woman-child": "\ec8c",
	"workflow": "\ec8d",
	"workingmouse": "\ec8e",
	"workingmouse-old": "\ec8f",
	"work-item": "\ec90",
	"wormhole": "\ec91",
	"cross": "\ec92",
	"x-circle": "\ec93",
	"x-wing": "\ec94",
	"youtube": "\ec95",
	"zoom-in": "\ec96",
	"zoom-out": "\ec97"
);
$admin-icon-name: "lightning-icons";

@each $name,
$char in $admin-icons {
	.icon-#{$name} {
		&:before,
		&:after {
			content: $char;
		}
	}
}
// % protected region % [Change icon sizes and padding] off begin
*[class*='icon-'] {
	&:before, &:after {
		font-family: $admin-icon-name;
	}

	//Left and top icons should be before classes
	&.icon-top,
	&.icon-left {
		&:after {
			display: none;
		}
	}

	&.icon-left {
		&:before {
			margin-right: $admin-space-sm;
		}
	}

	&.icon-top {
		display: block;

		&:before {
			display: block;
			margin-bottom: $admin-space-xs;
		}
	}

	//Right and bottom icons should be after classes
	&.icon-bottom,
	&.icon-right {
		&:before {
			display: none;
		}
	}

	&.icon-right {
		&:after {
			margin-left: $admin-space-sm;
		}
	}

	&.icon-bottom {
		display: block;

		&:after {
			display: block;
			margin-top: $admin-space-xs;
		}
	}

	&.icon-only {
		display: block;

		&:before {
			display: block;
			margin: $admin-space-xxs;
		}
		&:after{
			display: none;
		}
	}
}
// % protected region % [Change icon sizes and padding] end
// % protected region % [Add additional icons here] off begin
// % protected region % [Add additional icons here] end