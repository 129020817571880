/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Forms landing page with the squares
-------------------------------- */

// % protected region % [Make changes to forms landing page here] off begin
&.forms-behaviour__landing {
	padding: $admin-space-md $admin-space-lg;
	overflow: auto;

	.accordion {
		margin-top: $admin-space-lg;
		border-bottom: none;

		.icon-chevron-up {
			font-family: $admin-heading-font-family;
			font-size: $admin-text-lg;
			font-weight: $admin-text-medium;
			padding-bottom: $admin-space-md;
			border-bottom: convert-rem(3px) solid $admin-black;
			&:after {
				font-weight: $admin-text-regular;
			}
		}

		.accordion__info {
			background: transparent;

			.form-block-items {
				padding-top: $admin-space-md;
			}

			&--collapsed .forms-block-items {
				@include fadeOut($admin-animation-time);
			}

			&--expanded .forms-block-items {
				@include fadeIn($admin-animation-time);
			}
		}
	}

	.forms-block-items {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include media(xs, sm) {
			justify-content: center;
		}

		a {
			@include behaviour-blocks-a;
		}

		.form-item__new {
			@include behaviour-blocks-new;
		}

		.form-item {
			@include behaviour-blocks;

			.form-item__heading {
				h3 {
					font-weight: $admin-text-medium;
				}

				.form-responses {
					margin: 0;
					font-size: $admin-text-sm;
				}
			}

			.form-item__footer {
				display: flex;
				justify-content: space-between;
				align-content: baseline;
				max-height: convert-rem(40px);
				margin-top: auto;

				.form-item__versions {
					@include block-versions;
				}

				.icon-more-horizontal {
					margin-left: auto;
					margin-top: auto;
					padding: 0;
				}
			}
		}
	}
}
// % protected region % [Make changes to forms landing page here] end
// % protected region % [Add additional styles here] off begin
// % protected region % [Add additional styles here] end