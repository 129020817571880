/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Workflow Mobile styling
-------------------------------- */



// % protected region % [Change workflows mobile view styles here] off begin
@include media(xs, sm) {
	&.workflow-behaviour__landing {
		padding: $admin-space-xxs $admin-space-xs;

		.workflow-block-items {
			justify-content: center;
		}
	}

	.workflow__states {
		display: grid;
		grid-template-areas: "sidelist statebuilder";
		grid-template-columns: minmax(0,auto) 1fr;

		.workflow-states__wrapper {
			padding: $admin-space-xxs $admin-space-xs;


			h3 {
				font-size: $admin-text-md;
			}

			//each state line
			.workflow__states-step {
				padding: $admin-space-xxs $admin-text-xs;
				flex-wrap: wrap;

				//start button with the flag
				.workflow__start-state,
				.workflow__delete-state {
					width: 100%;
					display: flex;
					margin: $admin-space-xs auto;

					&:before {
						margin-right: $admin-space-xs;
						margin-bottom: 0;
					}
				}

				.input-group {
					width: 100%;
					margin: $admin-space-xxs 0;
				}

				//incoming and outgoing section
				.workflow__incoming,
				.workflow__outgoing {
					margin: auto;
				}

				.workflow__outgoing {
					div {
						display: flex;
						align-items: center;
					}
				}

				//hover and active of state row
				&:hover,
				.active {
					background-color: $admin-grey-0;
					@include transform-timing($admin-animation-time);
				}
			}

			//add new state btn
			.workflow__new-state .btn {
				margin: $admin-space-md auto;
			}
		}
	}
}

// % protected region % [Change workflows mobile view styles here] end
// % protected region % [Add additional workflows mobile view styles here] off begin
// % protected region % [Add additional workflows mobile view styles here] end