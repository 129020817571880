/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Selects
-------------------------------- */
/*
Styling for select inputs - your bot is most likely to have specific styles for multiselects
*/


// % protected region % [Change select styles here] off begin
&.input-group__select {
	select {
		grid-area: input;
		background-color: transparent;
		border-radius: $admin-input-radius;
		@include appearance-none;
		padding: $admin-space-xs;
		position: relative;
		min-width: convert-rem(220px);
		max-width: convert-rem(495px);

		option {
			min-width: convert-rem(220px);
			max-width: convert-rem(440px);
		}
	}

	&:after {
		position: absolute;
		top: 2rem;
		right: 1.4rem;
	}

	@include icon(after, chevron-down);

}

&.input-group__dropdown {
	input {
		margin: 0;
		padding: .2rem 0;
		outline: none;

		&:focus,
		&:active,
		&:hover {
			outline: none;
		}
	}
}
// % protected region % [Change select styles here] end
// % protected region % [Add additional select styles here] off begin
// % protected region % [Add additional select styles here] end