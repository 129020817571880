/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Textareas
-------------------------------- */
/*
Textareas currently have fixed widths - these are changeable
*/


// % protected region % [Change textarea styles here] off begin
&.input-group__textarea {
	width: 100%;
	@include media(xs, sm, md) {
		width: auto;
	}

	&.input-group--static {
		pointer-events: unset;
	}

	textarea {
		white-space: pre-wrap;
		width: auto;
		max-width: 100%;
		overflow-y: auto;
		line-height: 1.5;
		padding: $admin-space-sm;

		&.input-static {
			border: none;
		}
	}

	.tooltip {
		left: convert-rem(488px);
	}
}
// % protected region % [Change textarea styles here] end
// % protected region % [Add additional textarea styles here] off begin
// % protected region % [Add additional textarea styles here] end