/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */

// % protected region % [Configure combobox styles here] off begin
.input-group__dropdown {
	input {
		width: 100%;
	}
	.ui.selection.dropdown {
		background: transparent; 
		color: get-contrasting-text-color($background) !important;
		>.text:not(.default) {
			color: get-contrasting-text-color($background) !important;
		}
		&.disabled {
			background: $grey-0;
			border: none;
			opacity: 0.8;
			color: transparentize(get-contrasting-text-color($grey-0), .2);
			&:hover {
				border-color: none;
			}

			.search, .dropdown {
				opacity: 1;
			}
		}
		.menu{
			margin: 0 -2px;
			min-width: calc(100% + 3px);
			border-top-right-radius: 0;
			border-top-left-radius: 0;
			>.item {		
				color: get-contrasting-text-color($background) !important;
				border: none;
				padding: $space-xs $space-sm !important;
			}
			.selected.item, .selected {
			}
		}
	}
	.dropdown__container {
		grid-area: input;
		background-color: transparent;
		line-height: 1;
		font-size: $input-font-size;
		vertical-align: top;
		border-radius: $input-radius;
		color: get-contrasting-text-color($background);
		font-weight: $text-regular;
		border: 2px solid get-contrasting-text-color($background);
		input {
			margin-top: 0.25rem;
		}
		.ui.selection.active.dropdown{
			&:hover {
				border: 2px solid get-contrasting-text-color($background);
				.menu {
					border-color: get-contrasting-text-color($background) !important;
				}
			}
		}

		.ui.selection.dropdown .menu, .ui.selection.active.dropdown .menu {
			background-color: $grey-2;
			border-radius: 0 !important;
			border: 2px solid get-contrasting-text-color($background) !important;
			* {
				color: get-contrasting-text-color($background);
			}
		}
		&.active, &.active:hover, &:hover, &:focus, .menu {
			border: 2px solid get-contrasting-text-color($background) !important;
			@include transform-timing($animation-time);
			border-radius: $input-radius;
		}

		&.single {
			padding: $space-xs $space-sm;
			margin: $space-xs 0;
		}

		&.multiple {
			margin: $space-xs 0 0 0;
			padding: $space-xxs $space-sm;

			> .default.text {
				color: get-contrasting-text-color($background);
				font-weight: $text-light;
				opacity: 1;
			}

			> .label {
				border: none;
				text-decoration: none;
				background-color: $black;
				color: get-contrasting-text-color($black);
				padding: 0.5rem;
				font-size: 85%;
				border-radius: 0;
				font-weight: 400;
				line-height: 12.24px;

				> i.icon.delete {
					opacity: 1;
					margin-left: 10px;

					&:before {
						color: get-contrasting-text-color($background);
					}

					&:hover:before {
						color: $hover-state;
					}
				}
			}

			&:focus {
				outline: none;
			}
		}

		& > div {
			padding: 0;
		}
	}
}

/*This will enable dropdown fields requiring validation to manifest the same border red color as regular error inputs*/
.input-group--error{
	.dropdown__container{
		border-color: $color-support-red;
	}
}
// % protected region % [Configure combobox styles here] end