/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
@use "sass:math";

/* ----------Pick your brand colours here ----------*/
/* -------------------------------------------------*/

//Primary and theme colour
$color-primary: rgb(21, 21, 23); // #151517 // Black
$color-secondary: rgb(197, 197, 197); // #c5c5c5 // Grey
$color-brand-01: rgb(57, 57, 57) !default; // #393939 // Charcoal
$color-brand-02: rgb(213, 213, 213) !default; // #D5D5D5; // Grey
$color-brand-03: rgb(244, 244, 244) !default; //#F4F4F4 // Light grey

// Accent Colours
$color-support-red: rgba(246, 70, 79, 1) !default; // #E84D38 // Support color red
$color-support-green: rgba(35, 215, 158, 1) !default; // #38CC7D // Support color green
$color-support-blue: rgba(76, 98, 240, 1) !default; // #009FDD // Support color blue
$color-support-yellow: rgba(244, 220, 62, 1) !default; // #FBC200 // Support color yellow
$color-support-orange: rgba(251, 164, 65, 1) !default; // #F27600 // Support color orange
$color-support-purple: rgba(91, 15, 211, 1) !default; // #8623DF // Support color purple

//Greyscale
$black: rgb(34, 34, 34); // #000000
$grey-10: rgb(34, 34, 34); // #222222
$grey-8: rgb(71, 71, 71); // #474747
$grey-6: rgb(111, 111, 111); // #6f6f6f
$grey-4: rgb(152, 152, 152); // #989898
$grey-2: rgb(197, 197, 197); // #c5c5c5
$grey-1: rgb(221, 221, 221); // #dddddd
$grey-0: rgb(244, 244, 244); // #f4f4f4
$white: rgb(255, 255, 255); // #ffffff

//Assigning theme colours
$background: $white;
$hover-state:  $color-primary;
$element-hover:  $color-primary;

//Text color - do not use. Use for function 
$color-text-dark: $color-primary;
$color-text-light: $white;

//Colour gradients
@mixin gradient-palette($brand-colour-1, $brand-colour-2) {
    background: linear-gradient(90deg, $brand-colour-1, $brand-colour-2);
    background-repeat: no-repeat;
    color: (get-contrasting-text-color($brand-colour-1));

    &.btn,
    .btn {

        &:hover,
        &:active,
        &:focus {
            color: (get-contrasting-text-color($brand-colour-1));
        }
    }
}

@mixin flare {
    @include gradient-palette($color-support-red, $color-support-orange);
}

@mixin horizon {
    @include gradient-palette($color-brand-02, $color-support-orange);
}

@mixin aurora {
    @include gradient-palette($color-brand-01, $color-support-green);
}

@mixin galaxy {
    @include gradient-palette($color-support-blue, $color-brand-01);
}

@mixin gravity {
    @include gradient-palette($color-brand-01, $color-brand-02);
}

@mixin force {
    @include gradient-palette($color-brand-02, $color-brand-03);
}

@mixin nebula {
    @include gradient-palette($color-support-green, $color-brand-01);
}
/* --------------------------------
Functions
-------------------------------- */
/*
_functions.scss are used in various ways such as applying colour contrast, value conversions and setting themes.
Change and add functions according to your project.
*/

//****************-------COLOUR FUNCTIONS--------*****************

// Calculate the luminance for a color.
// See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
// % protected region % [Change colour functions here] off begin
@function luminance($color) {
    $red: nth($linear-channel-values, red($color) + 1);
    $green: nth($linear-channel-values, green($color) + 1);
    $blue: nth($linear-channel-values, blue($color) + 1);

    @return .2126 * $red + .7152 * $green + .0722 * $blue;
}

// Calculate the contrast ratio between two colors.
// See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
@function contrast($back, $front) {
    $backLum: luminance($back) + .05;
    $foreLum: luminance($front) + .05;

    @return math.div(max($backLum, $foreLum), min($backLum, $foreLum));
}

// Determine whether to use dark or light text on top of given color.
// Returns black for dark text and white for light text.
@function get-contrasting-text-color($color) {

    //Exceptional color contrast for the red colour, to force it to return white
    @if ($color == $color-support-red or $color == $color-brand-02 or $color == $color-brand-02){
        @return $color-text-light;
    }


    $lightContrast: contrast($color, $color-text-light);
    $darkContrast: contrast($color, $color-text-dark);

    @if ($lightContrast > $darkContrast) {
        @return $color-text-light;
    }

    @else {
        @return $color-text-dark;
    }

}

//  Precomputed linear color channel values, for use in contrast calculations.
//  See https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-tests
//
//  Algorithm, for c in 0 to 255:
//  f(c) {
//    c = c / 255;
//    return c < 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
//  }
//
//  This lookup table is needed since there is no `pow` in SASS.
$linear-channel-values:
    0
    .0003035269835488375
    .000607053967097675
    .0009105809506465125
    .00121410793419535
    .0015176349177441874
    .001821161901293025
    .0021246888848418626
    .0024282158683907
    .0027317428519395373
    .003035269835488375
    .003346535763899161
    .003676507324047436
    .004024717018496307
    .004391442037410293
    .004776953480693729
    .005181516702338386
    .005605391624202723
    .006048833022857054
    .006512090792594475
    .006995410187265387
    .007499032043226175
    .008023192985384994
    .008568125618069307
    .009134058702220787
    .00972121732023785
    .010329823029626936
    .010960094006488246
    .011612245179743885
    .012286488356915872
    .012983032342173012
    .013702083047289686
    .014443843596092545
    .01520851442291271
    .01599629336550963
    .016807375752887384
    .017641954488384078
    .018500220128379697
    .019382360956935723
    .0202885630566524
    .021219010376003555
    .022173884793387385
    .02315336617811041
    .024157632448504756
    .02518685962736163
    .026241221894849898
    .027320891639074894
    .028426039504420793
    .0295568344378088
    .030713443732993635
    .03189603307301153
    .033104766570885055
    .03433980680868217
    .03560131487502034
    .03688945040110004
    .0382043715953465
    .03954623527673284
    .04091519690685319
    .042311410620809675
    .043735029256973465
    .04518620438567554
    .046665086336880095
    .04817182422688942
    .04970656598412723
    .05126945837404324
    .052860647023180246
    .05448027644244237
    .05612849004960009
    .05780543019106723
    .0595112381629812
    .06124605423161761
    .06301001765316767
    .06480326669290577
    .06662593864377289
    .06847816984440017
    .07036009569659588
    .07227185068231748
    .07421356838014963
    .07618538148130785
    .07818742180518633
    .08021982031446832
    .0822827071298148
    .08437621154414882
    .08650046203654976
    .08865558628577294
    .09084171118340768
    .09305896284668745
    .0953074666309647
    .09758734714186246
    .09989872824711389
    .10224173308810132
    .10461648409110419
    .10702310297826761
    .10946171077829933
    .1119324278369056
    .11443537382697373
    .11697066775851084
    .11953842798834562
    .12213877222960187
    .12477181756095049
    .12743768043564743
    .1301364766903643
    .13286832155381798
    .13563332965520566
    .13843161503245183
    .14126329114027164
    .14412847085805777
    .14702726649759498
    .14995978981060856
    .15292615199615017
    .1559264637078274
    .1589608350608804
    .162029375639111
    .1651321945016676
    .16826940018969075
    .1714411007328226
    .17464740365558504
    .17788841598362912
    .18116424424986022
    .184474994500441
    .18782077230067787
    .19120168274079138
    .1946178304415758
    .19806931955994886
    .20155625379439707
    .20507873639031693
    .20863687014525575
    .21223075741405523
    .21586050011389926
    .2195261997292692
    .2232279573168085
    .22696587351009836
    .23074004852434915
    .23455058216100522
    .238397573812271
    .24228112246555486
    .24620132670783548
    .25015828472995344
    .25415209433082675
    .2581828529215958
    .26225065752969623
    .26635560480286247
    .2704977910130658
    .27467731206038465
    .2788942634768104
    .2831487404299921
    .2874408377269175
    .29177064981753587
    .2961382707983211
    .3005437944157765
    .3049873140698863
    .30946892281750854
    .31398871337571754
    .31854677812509186
    .32314320911295075
    .3277780980565422
    .33245153634617935
    .33716361504833037
    .3419144249086609
    .3467040563550296
    .35153259950043936
    .3564001441459435
    .3613067797835095
    .3662525955988395
    .3712376804741491
    .3762621229909065
    .38132601143253014
    .386429433787049
    .39157247774972326
    .39675523072562685
    .4019777798321958
    .4072402119017367
    .41254261348390375
    .4178850708481375
    .4232676699860717
    .4286904966139066
    .43415363617474895
    .4396571738409188
    .44520119451622786
    .45078578283822346
    .45641102318040466
    .4620769996544071
    .467783796112159
    .47353149614800955
    .4793201831008268
    .4851499400560704
    .4910208498478356
    .4969329950608704
    .5028864580325687
    .5088813208549338
    .5149176653765214
    .5209955732043543
    .5271151257058131
    .5332764040105052
    .5394794890121072
    .5457244613701866
    .5520114015120001
    .5583403896342679
    .5647115057049292
    .5711248294648731
    .5775804404296506
    .5840784178911641
    .5906188409193369
    .5972017883637634
    .6038273388553378
    .6104955708078648
    .6172065624196511
    .6239603916750761
    .6307571363461468
    .6375968739940326
    .6444796819705821
    .6514056374198242
    .6583748172794485
    .665387298282272
    .6724431569576875
    .6795424696330938
    .6866853124353135
    .6938717612919899
    .7011018919329731
    .7083757798916868
    .7156935005064807
    .7230551289219693
    .7304607400903537
    .7379104087727308
    .7454042095403874
    .7529422167760779
    .7605245046752924
    .768151147247507
    .7758222183174236
    .7835377915261935
    .7912979403326302
    .799102738014409
    .8069522576692516
    .8148465722161012
    .8227857543962835
    .8307698767746546
    .83879901174074
    .846873231509858
    .8549926081242338
    .8631572134541023
    .8713671191987972
    .8796223968878317
    .8879231178819663
    .8962693533742664
    .9046611743911496
    .9130986517934192
    .9215818562772946
    .9301108583754237
    .938685728457888
    .9473065367331999
    .9559733532492861
    .9646862478944651
    .9734452903984125
    .9822505503331171
    .9911020971138298
    1
;

//****************-------END OF COLOUR FUNCTIONS--------*****************


//****************-------PSEUDO ELEMENTS MIXINS--------****************
@mixin pseudo($display: block, $pos: absolute, $content: '') {
	content: $content;
	display: $display;
	position: $pos;
}

//****************-------SHAPES--------*****************

/*triangle creator - usually used for tooltips*/

@mixin css-triangle($color, $direction, $size: convert-rem(6px), $position: absolute, $round: false) {
	@include pseudo($pos: $position);
	width: 0;
	height: 0;

	@if $round {
		border-radius: convert-rem(3px);
	}

	@if $direction==down {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-top: $size solid $color;
		margin-top: 0 - round(math.div($size, 2.5));
	}

	@else if $direction==up {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
		margin-bottom: 0 - round(math.div($size, 2.5));
	}

	@else if $direction==right {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
		margin-right: -$size;
	}

	@else if $direction==left {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
		margin-left: -$size;
	}
}