/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Workflow Behaviour 
-------------------------------- */
/* 
Multiple imports are here such as sidebar, landing, and preview. 
*/


// % protected region % [Change workflows behaviour styles here] off begin
.workflow-behaviour {
	@import 'workflows-landing.scss';

	.workflow__create,
	.workflow__edit,
	.workflow__view {
		overflow-y: visible;
		overflow: auto;

		.input-group {
			max-width: 500px;
			margin: $admin-space-md;
		}
	}

	//States tab activated 
	@import 'workflows-state.scss';

	.workflow__action-bar {
		.workflow__status {
			align-items: center;
			margin-left: $admin-space-sm;

			div {
				@extend .btn;
				@extend .btn--solid;
				@extend .btn--secondary;
				padding: $admin-space-xs $admin-space-md;
				cursor: text;

				&:hover,
				&:active {
					cursor: text;
					background-color: $admin-color-secondary;
					color: $admin-white;
				}
			}
		}
		.workflow__actions {
			margin-left: auto;
		}
	}
	@import 'workflows-mobile.scss';
}

// % protected region % [Change workflows behaviour styles here] end
// % protected region % [Add additional workflows behaviour styles here] off begin
// % protected region % [Add additional workflows behaviour styles here] end