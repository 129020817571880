/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
/* --------------------------------
Forms mobile responsive styles
-------------------------------- */

// % protected region % [Make changes to forms mobile view] off begin
@include media(xs, sm) {
	&.forms-behaviour__landing {
		padding: $admin-space-xs $admin-space-sm;

		.accordion {
			.icon-chevron-up {
				font-size: $admin-text-md;
				padding-bottom: $admin-space-sm;
			}
		}

		.forms-block-items {
			justify-content: center;
		}
	}

	.slide-builder {
		grid-template-areas: "sidelist""formbuilder";
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 2fr;

		//Left side when editing and creating the form
		.slide-builder__list {
			padding-bottom: $admin-space-xs;
			border-bottom: convert-rem(2px) solid $admin-grey-2;
			width: 100%;

			.accordion {
				.icon-chevron-up {
					max-width: convert-rem(320px);
				}
			}

			//slide edit state - when clicking on the three dots and the edit button
			&.slide-builder__list--edit-slide,
			&.slide-builder__list--edit-question {
				padding: $admin-space-xs;
			}
		}
	}

	.action-bar {
		.form-information {
			display: none;
		}
		.btn-group {
			flex-wrap: nowrap;
			overflow: auto;
			&:first-of-type {
				margin-left: $admin-space-lg;
			}
		}
	}
}
// % protected region % [Make changes to forms mobile view] end
// % protected region % [Add additional forms mobile view here] off begin
// % protected region % [Add additional forms mobile view here] end